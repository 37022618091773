<template>
  <div class="ConnectedAccountReturnedPage">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CCard>
      <CCardBody>
        <CRow>
          <CCol class="text-center">
            <h1 v-if="isConnectedAccountValidated" style="font-size: 26px;"><strong>🥳 Compte bancaire validé 🥳</strong></h1>
            <h1 v-else style="font-size: 26px;"><strong>⚠️ Compte bancaire non validé</strong></h1>
          </CCol>
        </CRow>

        <CRow v-if="isConnectedAccountValidated" class="mt-3" style="font-size: 18px;">
          <CCol class="text-center">
            <p>
              <strong>Félications, toutes vos informations ont bien été enregistrées ! 👍</strong>
            </p>
            <p>
              Vous pouvez dèsormais recevoir des paiements en ligne ! Créez directement un lien de paiement sur la page de la facturation.
            </p>
          </CCol>
        </CRow>

        <CRow v-else class="mt-3" style="font-size: 18px;">
          <CCol class="text-center">
            <p>
              <strong>Les process de validation s'est terminé mais votre compte n'a pas encore été validé</strong>
            </p>
            <p>
              <CButton color="outline-primary" size="lg" @click="createConnectedAccountOnboardingLink">Finaliser la configuration de votre compte</CButton>
            </p>
          </CCol>
        </CRow>

        <CRow>
          <CCol>
            <CButton class="mt-3" block shape="pill" color="outline-primary" size="lg" @click="$router.push('/')">
            Retour au tableau de bord
            </CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { APIUserConnected } from '@/api/APIUserConnected'
import { APIBossConnected } from '@/api/APIBossConnected'

const apiUserConnected = new APIUserConnected()
const apiBossConnected = new APIBossConnected()

export default {
  name: 'ConnectedAccountReturnedPage',
  components: {
    Loading,
  },
  data: function () {
    return {
      isLoading: true,

      isConnectedAccountValidated: true
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    }
  },
  created: function() {
    this.inspectAndVerifyConnectedAccount()
  },
  methods: {
    inspectAndVerifyConnectedAccount () {
      this.isLoading = true
      apiUserConnected.inspectAndVerifyConnectedAccount(this.token)
      .then((result) => {
        this.$store.commit('CONNECTED_ACCOUNT_INSPECTED', result.data)
        if (result.data.id && result.data.charges_enabled) {
          this.isConnectedAccountValidated = true
        }
        else {
          this.isConnectedAccountValidated = false
        }
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isLoading = false
      })
    },

    createConnectedAccountOnboardingLink () {
      this.isLoading = true
      apiBossConnected.createConnectedAccountOnboardingLink(this.token)
      .then((result) => {
        window.location.href = result.data.url
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isLoading = false
      })
    },
  }
}
</script>
